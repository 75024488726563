.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: white;
  padding: 0 150px 80px;
  align-items: center;
  text-align: center;

  border-radius: 0 0 75px 75px;

  .description {
    width: 650px;
    font-size: 17px;
    line-height: 23px;
    color: #5d5e5e;
    margin-bottom: 58px;
    margin-top: 20px;
  }
  .title {
    margin-top: 70px;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 0;
    strong {
      color: #00ae75;
    }
  }

  .shoppingLogo {
    margin-right: 28px;
  }

  .multiLogo {
    width: 136px;
    margin-bottom: 40px;
  }
}

@media (max-width: 768px) {
  .container {
    width: auto;
    padding: 60px 40px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-radius: 29px;

    .title {
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      margin: 0 0 25px;
    }
    .description {
      width: 284px;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 33px;
    }
    .multiLogo {
      margin-bottom: 33px;
    }
    .shoppingLogo {
      margin-right: 8px;
    }
    .divider {
      display: inline-flex;
      border-right: 1px solid #c7c7c7;
      margin-right: 6px;
      height: 28px;
    }
    .shoppingBox {
      width: 301px;
    }
  }
}
