.container {
  display: flex;
  justify-content: center;
  border-top-left-radius: 75px;
  border-top-right-radius: 75px;
  flex-direction: column;
  background-color: white;
  padding: 20px 20px;
  align-items: center;
  text-align: center;

  .featureBoxRow {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .boxContainer {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;

    margin-bottom: 25px;
  }

  .boxContainer::nth-of-type(-n + 3) {
    margin-bottom: 200px;
  }

  .description {
    font-size: 17px;
    line-height: 23px;
    color: #5d5e5e;
    margin-bottom: 58px;
    width: 37%;
    strong {
      font-weight: 600;
    }
  }
  .title {
    margin-top: 130px;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 0;
    i {
      color: #00ae75;
    }
  }

  .moreInfo {
    color: #008258;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    text-align: center;
    letter-spacing: 1.36px;

    svg {
      display: block;
      margin: 0 auto 9px;
      width: 19px;
    }
    svg:last-of-type {
      width: 13px !important;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 20px 0px 20px 30px;
    .textBox {
      padding-right: 30px;
    }
    .title {
      margin-top: 40px;
    }
    .title,
    .subtitle {
      font-size: 19px;
      line-height: 22px;
    }
    .description {
      font-size: 12px;
      line-height: 17px;
      margin-bottom: 26px;
      width: 307px;
    }
    .boxContainer {
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 28px;
    }
  }
}
