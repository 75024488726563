@import '../../rem.scss';

.container {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #e8f3ff;
  padding: 20px 200px 0;
  height: 294px;
  div:nth-child(2) {
    width: 330px !important;
    height: rem(386px) !important;

    @media (max-width: 768px) {
      width: auto !important;
      height: auto !important;
      display: flex;
    }
  }
  video {
    height: rem(386px) !important;
    width: rem(329px) !important;
    object-fit: cover;

    @media (max-width: 768px) {
      height: auto !important;
    }
  }

  .placeholderImage {
    height: 386px;
    margin-bottom: -26px;
    @media (max-width: 768px) {
      height: 286px;
      margin-bottom: -19px;
    }
  }

  .textBox {
    margin-right: 58px;
    text-align: right;
    .description {
      width: 352px;
      font-size: 17px;
      line-height: 23px;
      color: #5d5e5e;
      margin-bottom: 58px;
      strong {
        font-weight: 600;
      }
    }
    .title {
      width: 352px;
      font-size: 30px;
      line-height: 35px;
      color: #4a4a4a;
      margin: 0;
      strong {
        color: #00ae75;
        display: block;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 50px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;

    .textBox {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 0;
      text-align: center;
      h1 {
        font-size: 19px;
        line-height: 22px;
        width: 304px;
        margin-bottom: 25px;
      }
      .description {
        width: 295px;
        font-size: 12px;
        line-height: 16px;

        color: #5d5e5e;

        margin-bottom: 40px;
        strong {
          font-weight: 600;
        }
      }
    }
    img {
      max-width: 100%;
    }
  }
}
