.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 11px;
    line-height: 13px;
    color: #5d5e5e;
    strong {
      font-weight: 600;
      cursor: pointer;
    }
  }
  svg {
    width: 33px;
    height: 33px;
    cursor: pointer;
  }
  svg:last-of-type {
    margin-left: 15px;
  }
  margin-bottom: 10px;
}
