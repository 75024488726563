@import '../../rem.scss';

.moreInfo {
  color: #008258;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;

  text-align: center;
  letter-spacing: 1.36px;

  svg {
    display: block;
    margin: 0 auto 9px;
    width: 19px;
  }
  svg:last-of-type {
    width: 13px !important;
  }
}
.container {
  background-color: white;
  padding: 80px 0px 0 200px;

  display: flex;
  justify-content: space-between;
  .imgBox {
    position: relative;

    width: 850px;
    height: 566px;
    img {
      width: 100%;
      height: 566px;
      transition: opacity 1s ease-in-out;
      position: absolute;
    }
    .transparent {
      opacity: 0;
    }
  }

  .textBox {
    width: 352px;
    margin-right: 104px;
    h1 {
      font-size: 30px;
      line-height: 35px;
      strong {
        color: #00ae75;
      }
    }
    p {
      color: #5d5e5e;
      font-size: 17px;
      line-height: 23px;
      strong {
        font-weight: 600;
      }
    }
  }
  .buttonGroup {
    display: flex;
    margin-left: 46px;
    margin-top: 124px;

    // .selectedBox {
    //   margin-right: 20px !important;
    // }

    .buttonBox {
      margin-right: 75px;

      text-align: center;
      button {
        border: none;
        outline: none;

        width: 34px;
        height: 34px;
        background-color: #8364be;
        border-radius: 50%;

        cursor: pointer;
      }

      p {
        position: absolute;
        width: 138px;
        font-size: 13px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.07em;
        text-transform: uppercase;
        margin-left: -51px;
        margin-top: 6px;
      }

      .notSelected {
        background-color: #f5f5f5 !important;
      }

      .llvButton {
        background-color: #d83d34;
      }

      .cuponsButton {
        background-color: #045ac9;
      }

      .cuponsDescription {
        color: #045ac9;
      }

      .llvDescription {
        color: #d83d34;
      }

      .notesDescription {
        color: #8364be;
      }
    }
  }
}

@media (max-width: 768px) {
  .moreInfo {
    margin-bottom: 38px;
  }
  .container {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .imgBox {
      position: relative;
      width: 100%;
      height: 337px;
      display: flex;
      align-items: flex-end;

      img {
        width: 100%;
        height: auto;
        transition: opacity 1s ease-in-out;
        position: absolute;
      }
      .transparent {
        opacity: 0;
      }
    }

    .buttonGroup {
      margin-top: rem(46px);
      .buttonBox {
        button {
          width: rem(15px);
          height: rem(15px);
        }
        p {
          width: rem(130px);
          font-size: rem(11px);
          line-height: rem(16px);
          margin-left: rem(-57px);
        }
      }
    }
    .textBox {
      width: rem(308px);
      margin-bottom: rem(80px);
      margin-right: 0;
      h1 {
        font-weight: 600;
        font-size: rem(19px);
        line-height: rem(22px);
      }
      p {
        font-weight: 400;
        font-size: rem(12px);
        line-height: rem(16px);
        color: #5d5e5e;
        strong {
          font-weight: 600;
        }
      }
    }
  }
}
