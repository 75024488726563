.main {
  background-color: #f5f5f5;
  width: 100%;
}

.container {
  padding: 120px 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1080px;
  margin: auto;

  z-index: 1;
  
  h1 {
    font-size: 42px;
    margin: 0;
    color: #5d5e5e;
  }

  h2 {
    font-size: 32px;
    margin: 0 0 0;
    color: #5d5e5e;
  }

  .textBox {
    color: #5d5e5e;
    p {
      font-size: 16px;
      line-height: 25px;
      color: #5d5e5e;
      margin-bottom: 43px;
      strong {
        font-weight: 600;
      }
    }
  }
}

.stepsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.step {
  flex: 1;
  text-align: center;
  margin: 0 10px;
}

.stepImage {
  width: 100%;
  max-width: 300px;
  height: auto;
}

.step p {
  font-size: 15px;
  color: #333;
}

@media (max-width: 768px) {
  .container {
    width: calc(100% - 40px);
    padding-top: 100px;
    display: flex;
    padding-bottom: 0;
    margin: 0;
    h1 {
      font-size: 28px;
      margin: 20px 0 0;
    }

    h2 {
      font-size: 24px;
    }
  }

  .stepsContainer {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
  }

  .step {
    margin: 0 0 20px;
  }
}
