.box {
  width: 283px;
  box-shadow: 0px 2px 8px rgba(141, 141, 141, 0.2664);
  border-radius: 15px;
  margin-right: 25px;
  margin-bottom: 45px;

  .boxTitle {
    display: flex;
    align-items: center;

    font-weight: 600;
    font-size: 16px;
    line-height: 20px;

    padding: 15px 30px;

    border-radius: 15px 15px 0 0;
    height: 72px;
    p {
      margin-left: 20px;
      text-align: left;
    }
  }
  .boxDescription {
    text-align: left;
    color: white;
    font-size: 15px;

    display: flex;
    align-items: center;

    padding: 0 15px;
    height: 96px;
    border-radius: 0 0 15px 15px;
    line-height: 19px;
    > p {
      b {
        font-weight: 600;
      }
    }
  }
}

@media (max-width: 768px) {
  .box {
    min-width: 221px;
    margin-bottom: 0;

    .boxTitle {
      padding: 10px 18px;
    }
    .boxDescription {
      padding: 0 25px;
    }
  }
}
