@import '../../rem.scss';

.container {
  display: flex;
  flex-direction: column;
  background-color: white;

  .descriptionContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .foodsContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 75px;
    img {
      width: 1000px;
      height: 141px;
    }
  }

  .goodsContainer {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 178px;
    margin-top: 45px;
    img {
      width: 1100px;
      height: 122px;
    }
  }

  .description {
    width: 688px;
    font-size: 17px;
    line-height: 23px;
    color: #5d5e5e;
    margin-bottom: 51px;
    margin-top: 15px;
    text-align: center;
    strong {
      font-weight: 600;
      color: #5d5e5e;
    }
  }
  .title {
    margin-top: 130px;
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: #4a4a4a;
    margin-bottom: 0;
    width: 723px;
  }
  .subtitle {
    font-size: 30px;
    line-height: 35px;
    text-align: center;
    color: #00ae75;
    margin-top: 0;
    margin-bottom: 0;
    display: inline;
  }

  .boxContainer {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .card {
      display: flex;
      width: 332px;
      align-items: center;
      justify-content: space-between;
      border: 2px solid #e7e7e7;
      box-sizing: border-box;
      border-radius: 14px;
      padding: 20px 30px;
      font-weight: 600;
      strong {
        color: #008258;
        font-weight: 600;
      }
      h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        width: 199px;
        color: #4a4a4a;
      }
    }
  }

  .card:last-of-type {
    margin-left: rem(25px);
    strong {
      color: #d67c2d;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 113px 0 20px;
    .title,
    .subtitle {
      width: 308px;
      font-size: 19px;
      line-height: 22px;
      margin-top: 0;
    }
    .description {
      font-size: 12px;
      line-height: 16px;
      width: 308px;
      margin-bottom: 0;
    }
    .card {
      max-width: 308px;
      max-height: 80px;
      padding: 20px 27px;
      border: 1px solid #e7e7e7;
      margin: 0 33px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 14px;
      strong {
        color: #008258;
      }
      h2 {
        font-size: 14px;
        line-height: 16px;
        width: 177px;
        margin: 0;
        font-weight: 600;
      }
      svg {
        width: 42px;
        height: 42px;
      }
    }
    .card:last-of-type {
      margin: 30px 33px;
      strong {
        color: #d67c2d;
      }
    }
    .foodsContainer {
      margin-top: 35px;
      img {
        width: 91%;
        height: 82px;
      }
    }

    .goodsContainer {
      margin-bottom: 50px;
      margin-top: 25px;
      img {
        width: 91%;
        height: 71px;
      }
    }
  }
}
