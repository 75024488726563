.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;

  .title {
    width: 368px;
    font-weight: 700;
    font-size: 26px;
    line-height: 30px;
    text-align: center;
    color: #4a4a4a;
    margin: 60px 0 22px;
  }

  .description {
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    color: #5d5e5e;
    margin-bottom: 35px;
    strong {
      font-weight: 600;
    }
  }
}
@media (max-width: 768px) {
  .container {
    padding: 0 45px;
    .title {
      font-size: 18px;
      line-height: 21px;
      width: auto;
    }
    .description {
      font-size: 12px;
      line-height: 16px;
    }
    button {
      font-size: 16px;
    }
  }
}
