@import './rem.scss';

html {
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  font-family: 'Work Sans';
  scroll-behavior: smooth;
}

body {
  font-family: 'Work Sans';
}

h1 {
  color: #4a4a4a;
}
