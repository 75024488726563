@import '../../rem.scss';

.containerBackground {
  position: absolute;
  height: 1100px;
  width: 100vw;
  background-color: #008258;
  z-index: -1;
}
.container {
  padding: 151px 7% 0;

  display: flex;
  justify-content: center;

  z-index: 1;

  div:nth-child(2) {
    text-align: center;
    width: 342px !important;
    margin-right: 70px;
  }

  video {
    width: rem(400px) !important;
    height: rem(646px) !important;
    object-fit: fill;
    background-color: transparent;
    @media (max-width: 768px) {
      width: rem(242px) !important;
      height: rem(457px) !important;
    }
  }
  .showcaseImage {
    width: auto;
    height: 600px;
    margin-right: 70px;
    margin-bottom: -35px;
    @media (max-width: 768px) {
      margin-right: 0;
      height: 500px;
      margin-bottom: -40px;
    }
  }

  .qrBox {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;

    margin-bottom: 96px;

    .qrDescription {
      width: 205px;

      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      text-align: right;

      color: #ffffff;
    }
  }

  .phoneImage {
    margin-bottom: -58px;
  }

  .textBox {
    width: 340px;
    color: white;
    margin-right: 70px;
    h1 {
      font-size: 42px;
      line-height: 49px;
      margin: 0 0 18px;
      color: white;
    }
    p {
      font-size: 17px;
      line-height: 25px;
      color: #e5f7f1;
      margin-bottom: 43px;
      strong {
        font-weight: 600;
      }
    }

    button:last-of-type {
      margin-bottom: 96px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    width: auto;
    padding: 131px 33px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: #008258;

    div:nth-child(2) {
      width: rem(232px) !important;
      height: rem(420px) !important;
      margin-right: 0;
    }

    .textBox {
      width: 308px;
      margin-right: 0;

      h1 {
        font-weight: 700;
        font-size: 27px;
        line-height: 32px;
        text-align: center;
      }
      p {
        font-weight: 400;
        font-size: 13px;
        line-height: 17px;

        color: #e5f7f1;
        strong {
          font-weight: 600;
        }
      }
      button {
        margin-bottom: 40px !important;
      }
    }

    button {
      width: 308px;
    }
  }
}
