.containerBackground {
  position: absolute;
  height: 200px;
  width: 100vw;
  background-color: #008258;
  z-index: -1;
  margin-top: -100px;
}
.container {
  padding: 55px 30px 0 191px;
  background-color: #008258;

  display: flex;
  justify-content: center;

  z-index: 1;

  .qrCodeImage {
    width: 134px;
    height: 134px;
    margin-right: 44px;
  }

  .multiPhoneImage {
    width: 605px;
    height: 470px;
  }
  .textBox {
    width: 477px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 110px;
    .buttonsBox {
      width: 100%;
      display: inline-flex;
      justify-content: space-between;
      button {
        width: 302px;
        height: 51px;
        margin-bottom: 32px;
      }
      button:last-of-type {
        margin-bottom: 0px;
      }
    }
    h1 {
      font-weight: 700;
      font-size: 39px;
      line-height: 46px;

      color: #e5f7f1;
      strong {
        color: white;
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    width: auto;
    padding: 50px 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: #008258;

    .textBox {
      width: 283px;
      margin-right: 0;

      h1 {
        font-weight: 700;
        font-size: 23px;
        line-height: 26px;
        text-align: center;
        margin-bottom: 40px;
      }
    }

    button {
      width: 283px;
      margin-bottom: 30px;
    }

    .multiPhoneImage {
      width: 283px;
      height: auto;
    }
  }
}
