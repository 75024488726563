.main {
  background-color: #f5f5f5;
  width: 100%;
  color: #5d5e5e;

}

.container {
  padding: 120px 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1080px;
  margin: auto;
  max-width: calc(100% - 40px);

  z-index: 1;
  
  h1 {
    font-size: 42px;
    margin: 0;
    color: #5d5e5e;
  }

  ul {
    display: flex;
    margin-top: 0;
    flex-direction: column;
    gap: 5px;
  }

  li {
    line-height: 24px;
  }

  h2 {
    font-size: 24px;
    margin: 43px 0 0 0;
    color: #5d5e5e;
  }

  h3 {
    margin-bottom: 0;
  }

  p {
    font-size: 16px;
    line-height: 24px;
    color: #5d5e5e;
    strong {
      font-weight: 600;
    }
  }

  @media (max-width: 768px) {
    h1 {
        font-size: 24px;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
      font-size: 16px;
    }
  }
}
