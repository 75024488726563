@import '../../rem.scss';

.container {
  background-color: #f5f5f5;
  padding: 70px 12% 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  video {
    width: rem(310px) !important;
    height: rem(412px) !important;
    object-fit: cover;
  }

  .placeholderImage {
    width: auto;
    height: 540px;
    @media (max-width: 768px) {
      height: 440px;
      margin-bottom: -70px;
    }
  }
  .reactPlayer {
    width: 400px !important;
  }

  .multiVCImage {
    margin-bottom: -150px;
  }

  .textBox {
    width: 352px;
    margin-left: 235px;
    img {
      width: 176px;
      height: 23px;
    }
    h1 {
      font-size: 30px;
      line-height: 35px;
      color: #4a4a4a;
      strong {
        color: #00ae75;
      }
    }
    p {
      color: #5d5e5e;
      font-size: 17px;
      line-height: 23px;
      strong {
        font-weight: 600;
      }
    }
    legend {
      font-size: 13px;
      line-height: 16px;
      color: #5d5e5e;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 56px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .reactPlayer {
      width: 280px !important;
    }

    .textBox {
      width: 308px;
      margin-bottom: 30px;
      margin-left: 0;
      img {
        width: 149px;
        height: 19px;
      }
      h1 {
        font-weight: 600;
        font-size: 19px;
        line-height: 22px;
        text-align: center;
        color: #4a4a4a;
        margin-top: 26px;
      }
      p {
        font-size: 12px;
        line-height: 16px;

        color: #5d5e5e;
        strong {
          font-weight: 600;
        }
      }
      legend {
        font-size: 10px;
        line-height: 11px;
      }
    }
  }
}
