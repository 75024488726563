button {
  cursor: pointer;
}

.button:hover {
  background-color: #e5f7f1 !important;
}

.button:active {
  background-color: #015c40 !important;
  color: white !important;
  svg {
    fill: white !important;
  }
}

.button {
  font-weight: 600;
  color: #015c40;
  font-size: 17px;
  line-height: 20px;

  border: none;

  align-items: center;
  display: flex;
  justify-content: center;

  background: #ffffff;
  width: 323px;
  height: 55px;

  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.305807);
  border-radius: 14px;

  margin-bottom: 43px;
  letter-spacing: 1.36px;

  svg {
    margin-right: 15px;
  }
}

.storeButton {
  background-color: transparent;
  border: 1px solid #008258;
  color: #008258;
  box-shadow: none;
  letter-spacing: 1.36px;
  font-weight: 600;
}

.ios {
  color: #008258;
}

.ios,
.android {
  justify-content: flex-start;
  padding-left: 20px;
}

@media (max-width: 768px) {
  .button {
    height: 44px;
  }
}
