@import '../../rem.scss';

.headerContainer {
  padding: rem(20px) rem(200px);
  background-color: white;
  position: fixed;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.305807);
  z-index: 1;
  transition: max-height 0.15s ease-in;
  max-height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .rightContainer {
    display: flex;
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    margin-right: rem(50px);
    color: #787575;
    font-size: rem(14px);
    line-height: rem(20px);
    letter-spacing: rem(1px);
  }

  .selected {
    color: #008258 !important;
    font-weight: 600;
  }

  .multiLogo {
    width: rem(100px);
    height: rem(26px);
  }
  .iosDownloadButton {
    width: rem(110px);
    height: rem(36px);
    margin-right: rem(13px);
  }
  .googleDownloadButton {
    width: rem(122px);
    height: rem(36px);
  }
  img {
    cursor: pointer;
  }
}
.headerOpen {
  max-height: 424px;
  transition: max-height 0.15s ease-out;
}

@media (max-width: 768px) {
  .headerContainer {
    display: inline;
    width: 100%;
    padding: rem(23px) 0;
    .linkBox {
      margin-top: 36px;
      .buttonWrapper {
        padding: 0 15px;
        button {
          font-size: 20px;
          line-height: 23px;
          color: #4a4a4a;
          border-bottom: 1px solid #f5f5f5;
          padding: 23px 15px;
          width: 100%;
          text-align: left;
        }
        button:active {
          background-color: #e5f7f1;
        }
      }
    }
    svg {
      margin-left: rem(34px);
      margin-right: rem(78px);
    }
    img {
      height: rem(24px);
    }
  }
}
