.bottomFooter {
  position: fixed;
  width: 100%;
  height: 73px;
  bottom: 0;
  background: #ffffff;
  box-shadow: 0px -2px 4px rgba(127, 127, 127, 0.321405);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  img {
    width: 142px;
    height: 42px;
  }
}
.nonScrollableFooter {
  box-shadow: none;
}
